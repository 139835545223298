<template>
  <v-card class="mx-auto" :disabled="loading" :loading="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'asegurados',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : table_data.length > 0
                  ? `(${table_data.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="contractor">
      <v-row dense>
        <v-col cols="12" v-if="!table_data.length">
          <p>
            Esta función revisara si los asegurados del contratante
            <b>{{ contractor.name }}</b> cuentan con ID SM iguales
            (agrupándolos) y únicos, asignado una letra ordenada alfabéticamente
            por la fecha de nacimiento más los últimos dígitos de su fecha de
            nacimiento. Este proceso una vez ejecutado será irreversible.
          </p>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-switch
            :label="'Añadir al final: ' + (is_letter ? 'Letra' : 'Número')"
            v-model="is_letter"
            :disabled="table_data.length > 0"
            class="mt-0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-switch
            :label="'Agregar día de nacimiento'"
            v-model="is_birthday"
            :disabled="table_data.length > 0"
            class="mt-0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-switch
            :label="
              'Ordenar por: ' +
              (order_insured_type ? 'Tipo de asegurado' : 'Fecha de nacimiento')
            "
            v-model="order_insured_type"
            :disabled="table_data.length > 0"
            class="mt-0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-switch
            :label="'Cambiar núm. asegurado si es único'"
            v-model="change_only_one"
            :disabled="table_data.length > 0"
            class="mt-0"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            v-if="!table_data.length"
            block
            small
            color="primary"
            @click.prevent="getRecalculate"
          >
            Continuar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
          <v-btn v-else block small @click.prevent="table_data = []">
            Cambiar parametros
            <v-icon right> mdi-update </v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="table_data.length > 0" cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID BD</th>
                  <th>Asegurado</th>
                  <th>F. nacimiento</th>
                  <th>Tipo</th>
                  <th>ID SM</th>
                  <th>Núm. asegurado</th>
                  <th>Nuevo núm. aseg.</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in table_data" :key="i">
                  <td>
                    <b v-text="i + 1" />
                  </td>
                  <td v-text="item.id" />
                  <td>
                    {{ item.full_name }}
                    <v-icon v-if="item.user_id" small color="success" right>
                      mdi-user-account
                    </v-icon>
                  </td>
                  <td v-text="item.birthday" />
                  <td v-text="item.insured_type_id" />
                  <td v-text="item.enrollment" />
                  <td v-text="item.credential" />
                  <td>
                    <b v-text="item.new_credential" />
                  </td>
                  <td>
                    <v-simple-checkbox v-model="item.to_save" :ripple="false" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col v-if="table_data.length > 0" cols="12" class="text-right">
          <v-btn small color="warning" @click.prevent="saveRecalculate">
            Actualizar información
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, msgConfirm, msgAlert } from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      contractor_id: parseInt(this.$route.params.contractor_id),
      contractor: null,
      loading: true,
      table_data: [],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      is_letter: true,
      is_birthday: false,
      order_insured_type: true,
      change_only_one: false,
    };
  },
  methods: {
    tableRowBg: function (item) {
      if (item.manual) return "red lighten-3";
      return "";
    },
    getRecalculate() {
      this.loading = true;
      this.table_data = [];

      Axios.get(
        URL_API +
          "/insureds/recalculate" +
          "?contractor_id=" +
          this.contractor_id +
          "&is_letter=" +
          this.is_letter +
          "&is_birthday=" +
          this.is_birthday +
          "&order_insured_type=" +
          this.order_insured_type +
          "&change_only_one=" +
          this.change_only_one,
        headersToken(this.login.token)
      )
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              this.table_data = res.data.data;
            } else {
              this.$swal.fire(
                msgAlert(
                  "warning",
                  "No se encontraron asegurados por modificar"
                )
              );
            }
          } else {
            console.log(res.data.message);
            this.$swal.fire(
              msgAlert("error", "Error en analisis de asegurados (2)")
            );
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire(
            msgAlert("error", "Error en analisis de asegurados (1)")
          );
          this.loading = false;
        });
    },
    saveRecalculate() {
      this.$swal
        .fire(msgConfirm(`¿Confirma actualizar la información?`))
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;

            let insureds = [];

            for (const obj of this.table_data) {
              if (obj.to_save) {
                insureds.push({
                  id: obj.id,
                  to_save: obj.to_save,
                  new_credential: obj.new_credential,
                  enrollment: obj.enrollment,
                  user_id: obj.user_id,
                });
              }
            }

            Axios.post(
              URL_API + "/insureds/recalculate",
              {
                insureds: insureds,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                res.data.success
                  ? this.$router.push({
                      name: "asegurados",
                      params: { contractor_id: this.contractor_id },
                    })
                  : console.log(response.message);
              } else {
                console.log(res.data.message);
              }

              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + "/contractors/" + this.contractor_id,
      headersToken(this.login.token)
    ).then((res) => {
      this.contractor = res.data.data;
      this.loading = false;
    });
  },
};
</script>